class Globals {

    constructor() {

        const closeMenu = document.getElementById('closeMenu');
        const menuTrigger = document.getElementById('toggleMenu');
        const closeSearch = document.getElementById('closeSearch');
        const openSearch = document.getElementById('openSearch');
        const searchSubmit = document.getElementById('searchArrowSubmit');
        const footerLinkNoClicks = document.querySelectorAll('.footer-container .noclick a');

        if(window.location.hash) {
            const hash = window.location.hash.substring(1);
            const location = document.getElementById(hash);

            if(location.parentNode.classList.contains('wp-block-ub-content-toggle-accordion-content-wrap')) {

                const immediateParent = location.parentNode;
                const immediateTarget = immediateParent.previousElementSibling;
                const parentContainer = this.findAncestor(immediateTarget, 'wp-block-ub-content-toggle-accordion-content-wrap');

                if(parentContainer) {
                    const parentSelector = parentContainer.parentNode.querySelector('.wp-block-ub-content-toggle-accordion-title-wrap');
                    parentSelector.click();
                }

                immediateTarget.click();

                document.querySelector('#' + hash).scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }

        Array.from(footerLinkNoClicks).forEach((link) => {
            link.addEventListener('click', e => {
                e.preventDefault();
            });
        });

        window.addEventListener('resize', () => {
            if(window.innerWidth > 1023) {
                this.closeMenu();
            }
        }, true);

        //Attach event listener to closeMenu.
        closeMenu.addEventListener("click", e => {
            this.closeMenu();
        });

        //Attach event listener to closeSearch.
        closeSearch.addEventListener("click", e => {
            this.closeSearch();
        });

        //Attach event listener to openSearch.
        openSearch.addEventListener("click", e => {
            this.closeMenu();
            this.openSearch();
        });

        //Attach event listener to open.
        menuTrigger.addEventListener("click", e => {
            this.openMenu();
        });

        //Attach event listener to search submit arrow.
        searchSubmit.addEventListener("click", e => {
            document.getElemenyById('searchsubmit').click();
        });
    }

    openMenu() {
        const body = document.querySelector('body');
        body.classList.add('menu-active');
    }

    closeMenu() {
        const body = document.querySelector('body');
        body.classList.remove('menu-active');
    }

    openSearch() {
        const body = document.querySelector('body');
        body.classList.add('search-active');
    }

    closeSearch() {
        const body = document.querySelector('body');
        body.classList.remove('search-active');
    }

    findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));

        return el;
    }

}

export default Globals;
